@tailwind base;
@tailwind components;
@tailwind utilities;

/* .bg-image-banner {
    background-image: url('/public/banner.jpg');
    background-size: contain;
} */

html {

    scroll-behavior: smooth;
}

.underlist li {
    color: #fff;
    padding-bottom: 10px;
    padding-top: 10px;
    transition: all 500ms ease-in;
    cursor: pointer;
}

.underlist li:nth-child(1) {
    padding-top: 0;
}

.underlist li:hover {
    border-bottom: 1px solid #ccc;

}

.border-left {
    border-left: 5px solid #1d4ed8;
    /* padding-bottom: 0px; */
}

.list-choose li {
    display: flex;
    padding-bottom: 15px;
    font-weight: 550;


}

.list-choose li svg {

    width: 20px;
    /* height: 20px; */
    margin-right: 12px;
}

.list-choose li svg path {
    fill: #1d4ed8;
}

.bg-color-service {
    background: rgb(207, 202, 254, 0.3);
    border-radius: 12px;
    /* border: 1px solid; */
}

.list-services {
    padding-top: 3rem;
}

.list-services li {
    display: flex;
    padding-bottom: 22px;
    font-size: 16px;

}

.list-services li svg {
    margin-right: 6px;
}

.center-highligth {
    transform: scale(1.05);
    background: rgba(29, 78, 216, 1);

}